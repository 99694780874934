<template>


 
  <b-card-code
    no-body
    title="Notifications"
  >

        <b-overlay :show="loading" rounded="sm">

          <b-table striped hover :items="notifications" :fields="fields">

              <template #cell(id)="data">
                <span v-b-tooltip.hover.top="data.item.id">
                    #
                </span>

              </template>


              <template #cell(createdAt)="data">
                  {{ data.item.createdAt|formatDateTime }}
              </template>

              <template #cell(severity)="data">
                      <b-badge :variant="data.item.severity">
                      {{ data.item.severity }}
                    </b-badge>              
              </template>

             <template #cell(actions)="data">
              
                    <b-button
                      @click="seen(data.item.id)"
                      variant="primary"
                      size="sm"
                      class="btn-tour-finish mr-1"
                    >
                    Seen
                    </b-button>

                  <a class="text-danger"  @click="remove(data.item.id)" >Remove</a>
              </template>


          </b-table>

         <div v-if="rows > 25" class="mt-1 d-flex justify-content-center">
              <b-pagination
                  v-model="currentPage"
                  hide-goto-end-buttons
                  :total-rows="rows"
                  :per-page="perPage"
                  @change="handlePageChange"
            />
          </div>

        </b-overlay>

  </b-card-code>

</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { BTable, BButton, VBTooltip, BPagination, BSpinner, BOverlay, BBadge } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { initializeApp } from "firebase/app"
import { getMessaging, getToken } from "firebase/messaging"

export default {
  components: {
    BCardCode,
    BTable,
    BButton,
    BPagination,
    BSpinner,
    BBadge,
    ToastificationContent,
    BOverlay
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data(){
    return {
          loading:false,
          notifications : [],
          fields: ['id', 'label', 'severity', 'category', 'data', 'emailAt', 'createdAt', 'actions'],
          currentPage:1,
          perPage:25,
          lastPage: 0,
          rows:0
    }

  },

  created(){
    this.loadNotifications();
  },

  methods : {

    handlePageChange(value) {

      this.page = value;

      this.loadNotifications(this.page)

    },

    loadNotifications(page=1){

      this.loading = true

      this.$store.dispatch('notification/fetchAll', page)
        .then((result) => {
            this.notifications = result.data.docs
            this.currentPage = result.data.page
            this.rows =  result.data.totalDocs

        },
        (error) => {
             this.showToast('Error', error.response?.data?.message || error?.message || 'Error while loading items', 'danger')


        }).finally(() => {

          this.loading =false

      })

    },

    seen(id) {

    },

    remove(id){

       this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-danger ',
          cancelButton: 'btn btn-outline-primary  ml-1',
        },
        buttonsStyling: false,

      }).then(result => {

          if(result.value === true) {

            this.loading = true

            this.$store.dispatch('notification/remove', id).then(
                result => {
                  console.log(result)
                  this.loadNotifications()

                },
                err=> {
                  console.log(err)
                }
              ).finally(() => {

                  this.loading =false

              })

          }

      },
      err=> {

      console.log(err)

      })

    },
    onStatusChange($event, id){

        const data = {
          id,
          status : event.target.value
        }


        this.$store.dispatch('notification/update', data)
        .then((result) => {

            this.showToast('Success', 'Item was updated successfully', 'success')

          },
          (error) => {

              this.showToast('Error', error.response?.data?.message || error?.message || 'Error while loading items', 'danger')

          })


    },
    showToast(title, text, variant) {
         this.$toast({
                component: ToastificationContent,
                props: {
                  title,
                  icon: 'InfoIcon',
                  text,
                  variant,
                },
         })
      }
  }
}
</script>
